var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cashkey-home" }, [
    _c("div", { staticClass: "amount" }, [
      _vm._m(0),
      _vm._v(" "),
      _c("div", { staticClass: "avail" }, [
        _vm._v(
          "\n            " + _vm._s(_vm.$t("webview.home.avail")) + "\n        "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c("div", { staticClass: "main" }, [
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v("\n                Monto del Préstamo\n            "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "btns" }, [
          _c(
            "button",
            {
              class: { active: _vm.amountActiveIndex === 0 },
              on: {
                click: function ($event) {
                  _vm.amountActiveIndex = 0
                },
              },
            },
            [_vm._v("\n                    $5,000\n                ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: { active: _vm.amountActiveIndex === 1 },
              on: {
                click: function ($event) {
                  _vm.amountActiveIndex = 1
                },
              },
            },
            [_vm._v("\n                    $10,000\n                ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "card" }, [
        _c("div", { staticClass: "text" }, [
          _vm._v("\n                Plazo\n            "),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "btns" }, [
          _c(
            "button",
            {
              class: { active: _vm.tenureActiveIndex === 0 },
              on: {
                click: function ($event) {
                  _vm.tenureActiveIndex = 0
                },
              },
            },
            [_vm._v("\n                    91 días\n                ")]
          ),
          _vm._v(" "),
          _c(
            "button",
            {
              class: { active: _vm.tenureActiveIndex === 1 },
              on: {
                click: function ($event) {
                  _vm.tenureActiveIndex = 1
                },
              },
            },
            [_vm._v("\n                    180 días\n                ")]
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("button", { staticClass: "loan-btn", on: { click: _vm.loan } }, [
        _vm._v("\n            Préstamo\n        "),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "money" }, [
      _c("img", { attrs: { src: require("./img/icon.png"), alt: "" } }),
      _vm._v(" "),
      _c("span", [_vm._v("10,000")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }