<!-- @author ruiwang -->
<!-- @email ruiwang@yangqianguan.com -->
<!-- @date 2020-1-15 14:46:06 -->
<!-- @desc index.vue -->

<template>
    <div class="cashkey-home">
        <div class="amount">
            <div class="money">
                <img
                    src="./img/icon.png"
                    alt=""
                >
                <span>10,000</span>
            </div>

            <div class="avail">
                {{ $t('webview.home.avail') }}
            </div>
        </div>

        <div class="main">
            <div class="card">
                <div class="text">
                    Monto del Préstamo
                </div>
                <div class="btns">
                    <button
                        :class="{active: amountActiveIndex === 0}"
                        @click="amountActiveIndex = 0"
                    >
                        $5,000
                    </button>
                    <button
                        :class="{active: amountActiveIndex === 1}"
                        @click="amountActiveIndex = 1"
                    >
                        $10,000
                    </button>
                </div>
            </div>
            <div class="card">
                <div class="text">
                    Plazo
                </div>
                <div class="btns">
                    <button
                        :class="{active: tenureActiveIndex === 0}"
                        @click="tenureActiveIndex = 0"
                    >
                        91 días
                    </button>
                    <button
                        :class="{active: tenureActiveIndex === 1}"
                        @click="tenureActiveIndex = 1"
                    >
                        180 días
                    </button>
                </div>
            </div>

            <button
                class="loan-btn"
                @click="loan"
            >
                Préstamo
            </button>
        </div>
    </div>
</template>

<script type="babel/text">
import WebviewInterface from 'ssr-common/util/webview/yqg-webview-interface';

export default {
    name: 'Home',

    data() {
        return {
            amountActiveIndex: 1,
            tenureActiveIndex: 0
        };
    },

    methods: {
        loan() {
            WebviewInterface.callHandler('showLoginFlow');
        }
    }
};
</script>

<style lang="scss" scoped>
    @import "~olacred/common/style/index";

    @font-face {
        font-family: "HelveticaBlackT";
        src: url("~olacred/common/style/font-face/Helvetica-LT-Black.ttf");
    }

    .cashkey-home {
        display: flex;
        flex-direction: column;
        height: 100vh;
        background: $primary-orange;
        box-sizing: border-box;
        padding: 0.2rem 0 0;
        overflow: auto;
        max-width: $screen-sm;
        margin: auto;
        color: #fff;

        .header {
            padding: 0 0.25rem;

            .hello {
                display: flex;
                justify-content: space-between;
                align-items: center;
                font-size: 14px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                line-height: 18px;

                div {
                    display: flex;
                    align-items: center;
                }

                img {
                    flex: 0;
                    margin-right: 0.07rem;
                    height: 0.18rem;
                    widows: auto;
                }
            }

            .tips {
                margin-top: 0.06rem;
                padding-left: 0.25rem;
                font-size: 12px;
                line-height: 16px;
            }
        }

        button {
            outline: none;
            border: none;
            font-size: 16px;
        }

        .logo {
            width: 84px;
            margin-bottom: 21px;
        }

        .text {
            position: relative;
            color: #333;
            font-size: 14px;
            line-height: 18px;
            padding-left: 0.09rem;

            &::before {
                position: absolute;
                content: "";
                display: block;
                width: 0.04rem;
                height: 0.04rem;
                border-radius: 50%;
                background: #333;
                left: -0.05rem;
                top: 50%;
                transform: translateY(-50%);
            }
        }

        .amount {
            padding: 0 0.25rem;

            .money {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-top: 0.14rem;
                font-size: 40px;
                font-family: HelveticaBlackT;
                font-weight: 900;
                color: #fff;
                line-height: 50px;

                img {
                    flex: 0;
                    margin-right: 0.1rem;
                    height: 0.28rem;
                    width: 0.17rem;
                }
            }

            .avail {
                margin-top: 0.1rem;
                font-size: 14px;
                font-family: Helvetica-Bold, Helvetica;
                font-weight: bold;
                color: #fff;
                line-height: 18px;
            }
        }

        .main {
            flex: 1;
            background: #fff;
            border-radius: 20px 20px 0 0;
            margin-top: 0.4rem;
            padding: 0.3rem 0.25rem 0;
            min-height: 4.8rem;
        }

        .card {
            .btns {
                display: flex;
                align-items: center;
                justify-content: space-between;
                margin-top: 10px;
                padding-bottom: 0.2rem;

                button {
                    flex: 1;
                    height: 0.4rem;
                    border-radius: 20px;
                    border: 1px solid #dcdcdc;
                    color: #333;
                    background: #fff;
                    font-family: Helvetica-Bold, Helvetica;
                    font-weight: bold;

                    &:first-child {
                        margin-right: 15px;
                    }
                }

                .active {
                    border: 1px solid $primary-orange;
                    color: $primary-orange;
                }
            }

            &:nth-child(2) {
                margin-top: 0.2rem;
                padding-top: 0.2rem;
                border-top: 1px solid #dcdcdc;
            }
        }

        .loan-btn {
            width: 100%;
            height: 60px;
            background: $primary-orange;
            color: $base-white;
            border-radius: 30px;
            font-weight: bold;
            font-size: 18px;
            margin-top: 1.15rem;
        }

        .process-img {
            margin-top: 30px;
        }
    }
</style>
